import React from "react";
import TopBar from "../components/TopBar";
import {CssBaseline, ThemeProvider, Typography} from "@mui/material";
import {darkTheme, lightTheme} from "../Themes.js";
import BottomSection from "../components/BottomSection";
import {Trans, useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

function PrivacyPolicyBody() {
    const {t} = useTranslation("common");
    return (
        <Box sx={{m: 2, paddingTop: 9}}>
            <Typography align="justify">
            <Trans t={t} i18nKey="PrivacyPolicy.body"/>
            </Typography>
        </Box>
    )

}

export default function PrivacyPolicy() {
    return (
        <ThemeProvider theme={lightTheme}>
            <div className="App">
                <TopBar/>
                <CssBaseline/>
                <PrivacyPolicyBody/>
                <BottomSection/>
            </div>
        </ThemeProvider>
    );
}
