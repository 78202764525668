import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    Paper,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";

import CircleIcon from '@mui/icons-material/Circle';
import Divider from "@mui/material/Divider";
import Cover from "./Cover";
import {Trans, useTranslation} from "react-i18next";
import logo_iubo from "../images/avatar_iubo.jpg"
import logo_ipna from "../images/logo_ipna.png"
import logo_csic from "../images/logo_csic.png"
import logo_ulpgc from "../images/logo_ulpgc.png"
import logo_ull from "../images/logo_ull.png"
import logo_cifga from "../images/patrocinadores/cifga.png"
import logo_museos_tenerife from "../images/patrocinadores/museos_de_tenerife.png"
import logo_rseq_canarias from "../images/patrocinadores/rseq_canarias.jpg"
import logo_gqpn from "../images/patrocinadores/gqpn.jpg"
import logo_turismo from "../images/patrocinadores/turismo_lala.png"
import logo_biosigma from "../images/patrocinadores/logo_biosigma.png"
import template_es from "../documents/template_RIMATyb_2024_es.docx"
import template_pt from "../documents/template_RIMATyb_2024_pt.docx"
import template_powerpoint from "../documents/Template RIMATyB 2024 oral.pptx"
import program_pdf from "../documents/Programa RIMATyB 2024_Definitivo.pdf"
import book_pdf from "../documents/Libro de resúmenes RIMATyB 2024.pdf"


function BulletedListItem({text}) {
    return (<ListItem sx={{paddingX: {xs: 2, md: 3}}}>
        <ListItemIcon sx={{minWidth: "10%"}}>
            <CircleIcon color='primary' sx={{fontSize: 15}}/>
        </ListItemIcon>
        <Typography variant='body1' lineHeight={1.5} align='justify'>
            {/*<ListItemText*/}
            {/*    primary={text}*/}
            {/*    // secondary={'Secondary text'}*/}
            {/*/>*/}
            {text}
        </Typography>
    </ListItem>)
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.table_odd
    }, '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.table_even
    }, // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(name, date) {
    return {name, date};
}

const SponsorItem = (props) => {
    const {span_xs, span_xl, image, image_name, link} = props;
    return <Grid item xs={span_xs} xl={span_xl}>
        <a href={link}>
            <Box component='img' sx={{width: 1}}
                 src={image}
                 alt={image_name}
                 loading="lazy"/>
        </a>
    </Grid>;
}

function ImportantDatesTable() {
    const {t} = useTranslation("common");
    const rows = [createData(t("MainBody.table1.1"), t("MainBody.table1.2")), createData(t("MainBody.table2.1"), t("MainBody.table2.2")), createData(t("MainBody.table3.1"), t("MainBody.table3.2"))];
    return (<TableContainer component={Paper}>
        <Table>
            <TableBody>
                {rows.map((row) => (<StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                        <b>{row.name}</b>
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.date}</StyledTableCell>
                </StyledTableRow>))}
            </TableBody>
        </Table>
    </TableContainer>);
}

function EventsTable() {
    const {t} = useTranslation("common");
    return (<TableContainer component={Paper}>
        <Table>
            <TableBody>
                <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                        <Typography variant='h6'>
                            {t("MainBody.events.table0.1")}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        <Typography variant='h6'>
                            {t("MainBody.events.table0.2")}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                        <Typography variant='h6' align='center'>
                            {t("MainBody.events.table0.3")}
                        </Typography>
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                        {t("MainBody.events.table1.1")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        {t("MainBody.events.table1.2")}
                    </StyledTableCell>
                    <StyledTableCell>
                        {t("MainBody.events.table1.3")}
                        <BulletedListItem text=<strong>{t("MainBody.events.tablefree")}</strong>/>
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                        {t("MainBody.events.table2.1")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        {t("MainBody.events.table2.2")}
                    </StyledTableCell>
                    <StyledTableCell>
                        {t("MainBody.events.table2.3")}
                        <BulletedListItem text=<strong>{t("MainBody.events.tablefree")}</strong>/>
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                        {t("MainBody.events.table3.1")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        {t("MainBody.events.table3.2")}
                    </StyledTableCell>
                    <StyledTableCell>
                        <Trans t={t}
                               i18nKey='MainBody.events.table3.3'
                               components={{}}
                        />
                        <BulletedListItem text={t("MainBody.events.dinnerpayment")}/>
                    </StyledTableCell>
                </StyledTableRow>
            </TableBody>
        </Table>
    </TableContainer>);
}

const WrappedInCard = (props) => {
    const {id, title, children} = props;
    return <div id={id}>
        <Card
            elevation={0}
            id={id}
            sx={{
                marginY: 1, marginX: {xs: 0, md: 1}, bgcolor: "background.default", paddingTop: 9
            }}>
            <CardHeader title={title} titleTypographyProps={{variant: 'h4', color: 'secondary'}}/>
            {children}
        </Card>
    </div>;
}

export default function MainBody() {
    const [t, i18n] = useTranslation('common');
    return (<Box>
        <Box
            component="main"
            sx={{m: {xs: 0, md: 2}}}>
            <Cover/>

            <WrappedInCard id='section_book' title={t("MainBody.section_book.title")}>
                <CardContent sx={{marginX: {xs: 0, md: 3}}}>

                    <Grid container spacing={4} alignContent='center' alignItems='center' alignSelf='center'
                          justifyContent='center'>
                        <Grid item lg={6} xs={12}>
                            <Typography variant="body1" align='justify'>
                                <Trans t={t} i18nKey="MainBody.section_book.content"/>
                            </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <a href={book_pdf}>
                                    <Button variant="contained">
                                        {t("MainBody.section_book.button")}
                                    </Button>
                                </a>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </WrappedInCard>


            <WrappedInCard id='section_program' title={t("MainBody.section_program.title")}>
                <CardContent sx={{marginX: {xs: 0, md: 3}}}>

                    <Grid container spacing={4} alignContent='center' alignItems='center' alignSelf='center'
                          justifyContent='center'>
                        <Grid item lg={6} xs={12}>
                            <Typography variant="body1" align='justify'>
                                <Trans t={t} i18nKey="MainBody.section_program.content"/>
                            </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <a href={program_pdf}>
                                    <Button variant="contained">
                                        {t("MainBody.section_program.button")}
                                    </Button>
                            </a>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </WrappedInCard>

            <WrappedInCard id='section1' title={t("MainBody.section1.title")}>
                <CardContent sx={{marginX: {xs: 0, md: 3}}}>
                    <Grid container spacing={4} alignContent='center' alignItems='center' alignSelf='center'
                          justifyContent='center'>
                        <Grid item xs={12}>
                            <Typography variant="body1" align='justify'>
                                <Trans t={t} i18nKey="MainBody.section1.content"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <Typography variant="body1" align='justify'>
                                <Trans t={t} i18nKey="MainBody.section1.content2"/>
                            </Typography>
                        </Grid>
                        <SponsorItem span_xs={6} span_xl={6}
                                     image="https://redibal.ipma.pt/wp-content/uploads/2022/02/redibal_alfa.png"
                                     image_name="Redibal LOGO"
                                     link="http://www.redibal.org/"
                        />
                    </Grid>
                </CardContent>
            </WrappedInCard>

            <WrappedInCard id='section2' title={t("MainBody.section2.title")}>
                <CardContent sx={{marginX: {xs: 0, md: 3}, p: 0}}>
                    <List>
                        <BulletedListItem
                            text={t("MainBody.section2.thematic1")}/>
                        <BulletedListItem
                            text={t("MainBody.section2.thematic2")}/>
                        <BulletedListItem text={t("MainBody.section2.thematic3")}/>
                        <BulletedListItem
                            text={t("MainBody.section2.thematic4")}/>
                        <BulletedListItem
                            text={t("MainBody.section2.thematic5")}/>
                    </List>
                </CardContent>
            </WrappedInCard>

            <WrappedInCard id='section3' title={t("MainBody.section3.title")}>
                <CardContent sx={{p: {xs: 0, md: 3}}}>
                    <ImportantDatesTable/>
                </CardContent>
            </WrappedInCard>

            <WrappedInCard id='instructions' title={t("MainBody.instructions.title")}>
                <CardContent sx={{marginX: {xs: 0, md: 3}}}>
                    <Stack spacing={2}>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.instructions.content1"/>
                        </Typography>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.instructions.content2"/>
                        </Typography>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.instructions.content3"/>
                        </Typography>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.instructions.content4"/>
                        </Typography>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.instructions.content5"/>
                        </Typography>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.instructions.content6"/>
                        </Typography>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.instructions.content7"/>
                        </Typography>

                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <a href={i18n.language === 'pt' ? template_pt : template_es}>
                                <Button variant="contained">
                                    {t("MainBody.instructions.button_abstract")}
                                </Button>
                            </a>
                        </Box>

                        <Divider/>
                        <Typography variant="h6" gutterBottom align='justify'>
                            <u><Trans t={t} i18nKey="MainBody.instructions.send_instructions.title"/></u>
                        </Typography>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.instructions.send_instructions.subtitle"/>
                        </Typography>
                        <List>
                            <BulletedListItem text={t("MainBody.instructions.send_instructions.table0")}/>
                            <BulletedListItem text={t("MainBody.instructions.send_instructions.table1")}/>
                            <BulletedListItem text={t("MainBody.instructions.send_instructions.table2")}/>
                            <BulletedListItem text={t("MainBody.instructions.send_instructions.table3")}/>
                            <BulletedListItem text={t("MainBody.instructions.send_instructions.table4")}/>
                            <BulletedListItem text={t("MainBody.instructions.send_instructions.table5")}/>
                            <BulletedListItem text={t("MainBody.instructions.send_instructions.table6")}/>
                        </List>
                        <Divider/>
                        <Typography variant="h6" gutterBottom align='justify'>
                            <u><Trans t={t} i18nKey="MainBody.instructions.exhibition.title"/></u>
                        </Typography>

                        <Typography variant="h7" gutterBottom align='justify'>
                            <u><Trans t={t} i18nKey="MainBody.instructions.exhibition.subtitle1"/></u>
                        </Typography>

                        <List>
                            <BulletedListItem text={t("MainBody.instructions.exhibition.content1")}/>
                            <BulletedListItem text={t("MainBody.instructions.exhibition.content2")}/>
                            <BulletedListItem text={t("MainBody.instructions.exhibition.content3")}/>
                            <BulletedListItem text={t("MainBody.instructions.exhibition.content4")}/>
                            <BulletedListItem text={t("MainBody.instructions.exhibition.content5")}/>
                            <BulletedListItem text={t("MainBody.instructions.exhibition.content6")}/>
                        </List>

                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <a href={template_powerpoint}>
                                <Button variant="contained">
                                    {t("MainBody.instructions.button_powerpoint")}
                                </Button>
                            </a>
                        </Box>

                        <Typography variant="h7" gutterBottom align='justify'>
                            <u><Trans t={t} i18nKey="MainBody.instructions.exhibition.subtitle2"/></u>
                        </Typography>

                        <List>
                            <BulletedListItem text={t("MainBody.instructions.exhibition.content7")}/>
                            <BulletedListItem text={t("MainBody.instructions.exhibition.content8")}/>
                        </List>


                    </Stack>

                </CardContent>
            </WrappedInCard>

            <WrappedInCard id='events' title={t("MainBody.events.title")}>
                <CardContent sx={{p: {xs: 0, md: 3}}}>
                    <EventsTable/>
                    <br/>
                    <Box sx={{m: {xs: 2, md: 0}}}>
                        <Typography variant='h5'>
                            {t("MainBody.events.muna.title")}
                        </Typography>
                        <br/>
                        <Link href="https://www.museosdetenerife.org/muna-museo-de-naturaleza-y-arqueologia">
                            https://www.museosdetenerife.org/muna-museo-de-naturaleza-y-arqueologia
                        </Link>
                        <br/>
                        <br/>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.events.muna.content"/>
                        </Typography>

                        <br/>
                        <Typography variant='h5'>
                            {t("MainBody.events.lalaguna.title")}
                        </Typography>
                        <br/>
                        <Link href="https://turismo.aytolalaguna.es/">
                            https://turismo.aytolalaguna.es/
                        </Link>
                        <br/>
                        <br/>
                        <Typography variant="body1" gutterBottom align='justify'>
                            <Trans t={t} i18nKey="MainBody.events.lalaguna.content"/>
                        </Typography>
                    </Box>
                </CardContent>
            </WrappedInCard>

            <WrappedInCard id='travelling' title={t("MainBody.travelling.title")}>
                <CardContent sx={{p: {xs: 2, md: 3}}}>
                    <Typography variant="body1" gutterBottom align='justify'>
                        <Trans t={t} i18nKey="MainBody.travelling.content1"/>
                    </Typography>
                    <br/>
                    <Typography variant="body1" gutterBottom align='justify'>
                        <Trans t={t} i18nKey="MainBody.travelling.content2"/>
                    </Typography>
                    <br/>
                    <Typography variant="body1" gutterBottom align='justify'>
                        <Trans t={t} i18nKey="MainBody.travelling.content3"
                               components={{a: <Link>, </Link>}}
                        />
                    </Typography>
                    <br/>
                    <Typography variant="body1" gutterBottom align='justify'>
                        <Trans t={t} i18nKey="MainBody.travelling.content4"
                               components={{a: <Link>, </Link>}}
                        />
                    </Typography>
                </CardContent>
            </WrappedInCard>

            <WrappedInCard id='section4' title={t("MainBody.section4.title")}>
                <CardContent>
                    <Grid container spacing={2} textAlign="left" alignItems='center' justifyContent='center'>
                        <Grid item xs={12} xl={6}>
                            <Box>
                                <Typography variant="body1" lineHeight={1.6} sx={{marginTop: 1}}>
                                    José Javier Fernández Castro (ULL)<br/>
                                    Ana Raquel Díaz Marrero (IPNA-CSIC)<br/>
                                    Emilio Soler Onís (ULPGC)<br/>
                                    María Luisa Souto Suárez (ULL)<br/>
                                    Nathália Nocchi (ULL)<br/>
                                    Sara García Davis (ULL)<br/>
                                    Óscar Gómez Cárdenes (ULL)<br/>
                                    Víctor M. Hernández López (ULL)<br/>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <Grid container spacing={1} alignItems='center' justifyContent='center'>
                                <SponsorItem span_xs={6} span_xl={7} image={logo_ull} image_name={"Logo ULL"}
                                             link="https://www.ull.es"/>
                                <SponsorItem span_xs={4} span_xl={4} image={logo_iubo} image_name={"Logo IUBO"}
                                             link="https://www.ull.es/institutos/instituto-universitario-bio-organica/"/>
                                <SponsorItem span_xs={6} span_xl={6} image={logo_csic} image_name={"Logo CSIC"}
                                             link="https://www.csic.es/"/>
                                <SponsorItem span_xs={6} span_xl={6} image={logo_ipna} image_name={"Logo IPNA"}
                                             link="https://www.ipna.csic.es/"/>
                                <SponsorItem span_xs={6} span_xl={7} image={logo_ulpgc} image_name={"Logo ULPGC"}
                                             link="https://www.ulpgc.es/"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </WrappedInCard>

            <WrappedInCard id='section_scientific_committee' title={t("MainBody.scientific_committee.title")}>
                <CardContent>
                    <Grid container spacing={2} textAlign="left" alignItems='center' justifyContent='center'>
                        <Grid item xs={12} xl={12}>
                            <Box>
                                <Typography variant="body1" lineHeight={1.6} sx={{marginTop: 1}}>
                                    Dra. Patricia A. Tester (NOAA Fisheries) <br/>
                                    Dra. Christine J. Band Schmidt (CICIMAR, IPN) <br/>
                                    Dra. Ana Amorim (MARE-FCUL) <br/>
                                    Dr. Pedro Reis Costa (IPMA) <br/>
                                    Dr. Francisco Rodríguez (IEO-CSIC) <br/>
                                    Dr. Jorge Diogène (IRTA) <br/>
                                    Dr. Fernando Real (IUSA-ULPGC) <br/>
                                    Dra. Marta Sansón (ULL) <br/>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </WrappedInCard>
            <WrappedInCard id="patrocinios" title={t("MainBody.section6.title")}>
                <CardContent>
                    <Grid container spacing={2} alignItems='center' justifyContent='center'>
                        <SponsorItem span_xs={6} span_xl={4} image={logo_ull} image_name={"Logo ULL"}
                                     link="https://www.ull.es"/>
                        <SponsorItem span_xs={4} span_xl={3} image={logo_iubo} image_name={"Logo IUBO"}
                                     link="https://www.ull.es/institutos/instituto-universitario-bio-organica/"/>
                        <SponsorItem span_xs={6} span_xl={4} image={logo_csic} image_name={"Logo CSIC"}
                                     link="https://www.csic.es/"/>
                        <SponsorItem span_xs={6} span_xl={4} image={logo_ipna} image_name={"Logo IPNA"}
                                     link="https://www.ipna.csic.es/"/>
                        <SponsorItem span_xs={6} span_xl={4} image={logo_cifga} image_name={"Logo CIFGA"}
                                     link="https://cifga.com/"/>
                        <SponsorItem span_xs={6} span_xl={4} image={logo_museos_tenerife}
                                     image_name={"Logo Museos Tenerife"} link="https://www.museosdetenerife.org/"/>
                        <SponsorItem span_xs={12} span_xl={8} image={logo_rseq_canarias} image_name={"Logo RSEQ"}
                                     link="https://rseq.org/"/>
                        <SponsorItem span_xs={6} span_xl={4} image={logo_gqpn} image_name={"Logo GQPN"}
                                     link="https://gqpn.rseq.org/"/>
                        <SponsorItem span_xs={12} span_xl={8} image={logo_turismo} image_name={"Logo Turismo La Laguna"}
                                     link="https://turismo.aytolalaguna.es/"/>
                        <SponsorItem span_xs={6} span_xl={4} image={logo_biosigma} image_name={"Logo Biosigma"}
                                     link="https://www.biosigma.es/"/>
                    </Grid>
                </CardContent>
            </WrappedInCard>
        </Box>
    </Box>)
}

