import {createTheme, responsiveFontSizes} from "@mui/material";
import comfortaa from 'typeface-comfortaa';


const typography = {
    fontSize: 15,
    // align: 'justify',
    fontFamily: 'Comfortaa',
    body1: {
        lineHeight: 1.9,
        fontWeight: 400,
        align: 'justify'
    },
    h1: {
        fontWeight: 700,
        fontSize: 65,
        letterSpacing: 6,
    },
    h3: {
        fontWeight: 700,
        letterSpacing: 6
    },
    h4: {
        fontWeight: 700,
    },
    h6: {
        fontWeight: 700,
        fontSize: 19,
        letterSpacing: 1,
        lineHeight: 1,
    },
    button: {
        fontWeight: 800,
        textTransform: 'none'
    },
};
const breakpoints = {
    values: {
        xs: 0, sm: 400, md: 600, lg: 800, xl: 1000, sxl: 1200
    }
};

export const darkTheme = responsiveFontSizes(createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#ffb490",
        },
        secondary: {
            main: "#ff0000"
        },
        background: {
            paper: "#b6bdbf",
            default: "#1b2329",
            top: "#30373C",
            table_odd: "#151b20",
            table_even: "#1b2329"
        },
    },
    typography: typography,
    breakpoints: breakpoints
}));

export const lightTheme = responsiveFontSizes(createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#C45911",
        },
        secondary: {
            main: "#2F5597"
        },
        background: {
            paper: "#f3ece9",
            default: "#ffffff",
            top: "rgba(255,238,231,0.6)",
            bottom: "#ffeee7",
            table_odd: "#f9eae0",
            table_even: "#e6d9d0"
        },
    },
    typography: typography,
    breakpoints: breakpoints,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "#ffffff",
                }
            }
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [comfortaa],
            },
        },
    },
}));
