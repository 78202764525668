import React from "react";
import TopBar from "./components/TopBar";
import {CssBaseline, Grid, Stack, ThemeProvider, useMediaQuery} from "@mui/material";
import {darkTheme, lightTheme} from "./Themes.js";
import MainBody from "./components/MainBody";
import BottomSection from "./components/BottomSection";
import Box from "@mui/material/Box";
import PageIndex from "./components/PageIndex";
import cover from "./images/background.png"

function App() {
    const compact_layout = useMediaQuery(lightTheme.breakpoints.down('xl'), {
        defaultMatches: true
    });
    return (<ThemeProvider theme={lightTheme}>
        <div className="App">
            <CssBaseline/>
            {compact_layout ?
                    <Stack sx = {{
                        backgroundImage: `url(${cover})`,
                        backgroundRepeat: 'repeat',
                        backgroundSize: '50%',
                        width: 1
                    }}>
                    <Box alignContent='center' alignItems='center' alignSelf='center' sx={{
                        marginX: {
                            xs: 2, sm: 2, md: 2, lg: 2, xl: 2, sxl: 5
                        }, maxWidth: {
                            xs: 450, sm: 450, md: 500, lg: 500, xl: 1200, sxl: 9900
                        },
                        width: 1
                    }}>
                        <TopBar/>
                        <MainBody/>
                        <BottomSection/>
                    </Box>
                    </Stack>
                :
                <Stack sx = {{
                    backgroundImage: `url(${cover})`,
                    backgroundRepeat: 'repeat',
                    backgroundSize: '50%',
                }}>
                    <Box alignContent='center' alignItems='center' alignSelf='center' sx={{
                        marginX: {
                            xs: 2, sm: 2, md: 2, lg: 2, xl: 2, sxl: 5
                        }, maxWidth: {
                            xs: 500, sm: 500, md: 500, lg: 500, xl: 1200, sxl: 1200
                        },
                    }}>
                        <Grid container spacing={0}>
                            <Grid item container xs={4}>
                                <PageIndex/>
                            </Grid>
                            <Grid item xs={8}>
                                <MainBody/>
                                <BottomSection/>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            }
        </div>
    </ThemeProvider>);
}

export default App;
