import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import pt_file from "./translations/common/pt.json";
import es_file from "./translations/common/es.json";
import LanguageDetector from 'i18next-browser-languagedetector';
import emailjs from '@emailjs/browser';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";

i18next.use(LanguageDetector).init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: 'es', fallback: 'es', resources: {
        es: {
            common: es_file
        }, pt: {
            common: pt_file
        },
    },
});

const router = createBrowserRouter([
    {
        path: "/", element: <App/>,
    }
    ,
    {
        path: "/PrivacyPolicy", element: <PrivacyPolicy/>,
    }
]);

emailjs.init({
    publicKey: 'zyEGlT2QF0WdnEjVI'
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    <I18nextProvider i18n={i18next}>
        <RouterProvider router={router}/>
    </I18nextProvider>
</React.StrictMode>);
