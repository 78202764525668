import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Button, Drawer, IconButton, Stack, Toolbar, Typography
} from "@mui/material";
import RegistrationButtonAndDialog from "./RegistrationDialog";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {useState} from "react";
import logo_rimatyb from "../images/logo_rimatyb_azul_naranja_vertical.png";
import PageIndex from "./PageIndex";


export const LanguageSelector = () => {
    const [t, i18n] = useTranslation('common');

    return (<Stack direction="row">
        <IconButton sx={{p: 1}} onClick={() => {
            i18n.changeLanguage('es')
        }}>
            <img
                loading="lazy"
                width="26"
                src={`https://flagcdn.com/w40/es.png`}
                alt="ES"
            />
        </IconButton>
        <IconButton sx={{p: 1}} onClick={() => {
            i18n.changeLanguage('pt')
        }}>
            <img
                loading="lazy"
                width="26"
                src={`https://flagcdn.com/w40/pt.png`}
                alt="PT"
            />
        </IconButton>
    </Stack>);
}

function TopBar() {
    const {t} = useTranslation("common");
    const [open, setState] = useState(false);

    //function that is being called every time the drawer should open or close, the keys tab and shift are excluded so the user can focus between the elements with the keys
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState(open);
    };

    return (
        <AppBar sx={{
            // display: 'none',
            backgroundColor: 'background.default',
            // justifyContent: 'space-between',
            boxShadow: 0,
            position: 'sticky',
            paddingY: 0, paddingTop: 0, paddingRight: 1
        }}>
            <Toolbar>

            <Box alignContent='center' alignItems='center' alignSelf='center' sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 1,
                // maxWidth: {
                //     xs: 500,
                //     sm: 500,
                //     md: 500,
                //     lg: 500,
                //     xl: 1200,
                //     sxl: 9900
                // },
            }}>
                <Box sx={{p: 0, paddingLeft: 3, m: 0}}>
                <img style={{maxHeight: 55, objectFit: 'fit'}}
                     src={logo_rimatyb}
                     alt={"Logo RIMATyB24"}
                     loading="lazy"/>
                </Box>
                <Stack direction="row">
                    <LanguageSelector/>
                    <IconButton
                        size="large"
                        edge="start"
                        color="black"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{ml: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Stack>
            </Box>

            <Stack direction="row" sx={{m: 0}}>
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={toggleDrawer(false)}
                >
                    <Box onClick={toggleDrawer(false)}>
                        <PageIndex/>
                    </Box>

                </Drawer>
            </Stack>
            </Toolbar>
        </AppBar>
);
}

export default TopBar;