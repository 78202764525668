import * as React from 'react';
import Box from "@mui/material/Box";
import {Button, Card, CardContent, Stack, Typography} from "@mui/material";
import cover from "../images/cover_muted.jpg"
import Divider from "@mui/material/Divider";
import RegistrationButtonAndDialog from "./RegistrationDialog";
import {Trans, useTranslation} from "react-i18next";
import PlaceIcon from '@mui/icons-material/Place';

export default function Cover() {
    const [t, i18n] = useTranslation('common');

    return (
        <Box sx={{
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            // backgroundImage: `url(${cover})`,
            m: 0,
        }}>
            <Stack alignContent="center" alignItems="center"
                   sx={{paddingX: {xs: 1, md: 3}, paddingBottom: {xs: 4, md: 6}, paddingTop: {xs: 4}, display: "flex"
            }}>
                {/*<Typography variant="h1" sx={{marginY: 2}} align="center">*/}
                {/*    RIMATyB 2024*/}
                {/*</Typography>*/}

                <Typography variant="h5" align="center">
                    {t('cover.text1')}
                </Typography>

                <Divider style={{width: '50%'}} orientation="horizontal" sx={{m: 2}}/>

                <Typography variant="h3" sx={{m: 2}} align='center'>
                    {/*{i18n.language === 'es' ?  : template_es}*/}
                    {/*{t('cover.text2')}*/}
                    <Trans t={t}
                        i18nKey='cover.text2'
                        components={{ color: <font color='#2F5597'>, </font>}}
                    />
                </Typography>

                <Divider style={{width: '50%'}} orientation="horizontal" sx={{m: 2}}/>

                <Typography variant="h5" sx={{marginTop: 2}} align='center'>
                    {t('cover.date')}
                </Typography>

                <Typography variant="body1" sx={{marginBottom: 2}} align='center'>
                    {t('cover.place')}
                </Typography>

                <Button href="https://maps.app.goo.gl/dk7Z1fn1emWCTuek6" sx={{display: 'flex', justifyContent: 'center'}}>
                    <PlaceIcon sx={{width: 50, height: 50}} color='primary' />
                    <Typography color='secondary'> Google Maps </Typography>
                </Button>

                <Divider style={{width: '50%'}} orientation="horizontal" sx={{m: 2}}/>
                <Stack>
                        <Typography variant="body1" gutterBottom><i>
                            <Trans t={t} i18nKey="cover.prebutton" />
                        </i>
                        </Typography>
                        <RegistrationButtonAndDialog width="50%"/>
                    <Typography variant="body2" gutterBottom align='center'>
                        <Trans t={t} i18nKey="cover.postbutton" />
                    </Typography>
                </Stack>
                {/*    </CardContent>*/}
                {/*</Card>*/}
            </Stack>
        </Box>
    )
}

