import {useTranslation} from "react-i18next";
import {Stack, Typography, Link, IconButton, useMediaQuery} from "@mui/material";
import * as React from "react";
import logo_rimatyb from "../images/logo_rimatyb_azul_naranja_vertical.png";
import Box from "@mui/material/Box";
import {lightTheme} from "../Themes";


export const LanguageSelector = () => {
    const [t, i18n] = useTranslation('common');

    return (<Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
        <IconButton sx={{p: 1}} onClick={() => {
            i18n.changeLanguage('es')
        }}>
            <img
                loading="lazy"
                width="42"
                src={`https://flagcdn.com/w40/es.png`}
                alt="ES"
            />
        </IconButton>
        <IconButton sx={{p: 1}} onClick={() => {
            i18n.changeLanguage('pt')
        }}>
            <img
                loading="lazy"
                width="42"
                src={`https://flagcdn.com/w40/pt.png`}
                alt="PT"
            />
        </IconButton>
    </Box>);
}

export default function PageIndex() {
    const [t] = useTranslation('common');
    const compact_layout = useMediaQuery(lightTheme.breakpoints.down('xl'), {
        defaultMatches: true
    });
    return (<Box position='fixed' sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 1,
        width: 300,
        backgroundColor: (compact_layout ? 'background.default': 'none')
    }}>
        <Stack id='StackIndex' direction='column' sx={{
            width: 1
        }}>
            <Box sx={{m: 3}}>
                <img style={{maxHeight: 55, objectFit: 'fit'}}
                     src={logo_rimatyb}
                     alt={"Logo RIMATyB24"}
                     loading="lazy"
                />
            </Box>
            <Stack direction='column' spacing={3} sx={{marginLeft: 4, marginTop: 4}} color='secondary.main'>
                <Link href="#section_book" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.section_book.title")}
                    </Typography>
                </Link>
                <Link href="#section_program" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.section_program.title")}
                    </Typography>
                </Link>
                <Link href="#section1" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.section1.title")}
                    </Typography>
                </Link>
                <Link href="#section2" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.section2.title")}
                    </Typography>
                </Link>
                <Link href="#section3" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.section3.title")}
                    </Typography>
                </Link>
                <Link href="#instructions" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.instructions.title")}
                    </Typography>
                </Link>
                <Link href="#events" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.events.title")}
                    </Typography>
                </Link>
                <Link href="#travelling" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.travelling.title")}
                    </Typography>
                </Link>
                <Link href="#section4" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.section4.title")}
                    </Typography>
                </Link>

                <Link href="#section_scientific_committee" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.scientific_committee.title")}
                    </Typography>
                </Link>
                <Link href="#patrocinios" underline="hover">
                    <Typography variant='h6' color='secondary'>
                        {t("MainBody.section6.title")}
                    </Typography>
                </Link>
                <LanguageSelector/>
            </Stack>
        </Stack>
        <Box>
        </Box>
    </Box>)

}
