import React from 'react';
import Box from "@mui/material/Box";
import {Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const WrappedContent = (props) => {
    const {id, children} = props;
    return <Box sx={{display: 'flex', padding: 2, justifyContent: 'center'}} id={id}>
        {children}
    </Box>;

}

export default function BottomSection() {
    const {t} = useTranslation("common");
    return (<div>
        <Stack sx={{bgcolor: "background.default", p: 3, width: '100%'}}>
            <Typography variant='body1' textAlign='center'>
                © Copyright 2024 RIMATyB | <Link to={"/PrivacyPolicy"}> {t("bottom.privacy_policy")} </Link>
            </Typography>
        </Stack>

    </div>)
}
