import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Button, CircularProgress, Dialog, DialogContent, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Box from "@mui/material/Box";
import CountrySelector from "./CountrySelector";
import Divider from "@mui/material/Divider";
import emailjs from "@emailjs/browser";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ReCAPTCHA from "react-google-recaptcha";

export default function RegistrationButtonAndDialog(width) {
    const {t, i18n} = useTranslation("common");
    const [open, setOpen] = useState(false);
    const [activeState, setActiveState] = React.useState(0);
    let language = i18n.language

    const [presentationType, setPresentationType] = React.useState(t("RegistrationDialog.label8"));

    function handleClick() {
        setActiveState(0);
        setOpen(true);
        // Cache chosen language now
        language = i18n.language
        setPresentationType(t("RegistrationDialog.label8"));
    }

    let [nameHasError, setNameHasError] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = React.useState("");
    let [nameInput, setNameInput] = useState('');
    let [nameCorrect, setNameCorrect] = useState(false);

    function nameHandleChange(event) {
        if (event.target.value.length === 0) {
            setNameHasError(true);
            setNameErrorMessage(t("RegistrationDialog.label14"));
            setNameCorrect(false);
        } else {
            setNameHasError(false);
            setNameErrorMessage("");
            setNameCorrect(true);
        }
        setNameInput(event.target.value);
    }

    let [surnameHasError, setSurnameHasError] = useState(false);
    const [surnameErrorMessage, setSurnameErrorMessage] = React.useState("");
    let [surnameInput, setSurnameInput] = useState('');
    let [surnameCorrect, setSurnameCorrect] = useState(false);

    function surnameHandleChange(event) {
        if (event.target.value.length === 0) {
            setSurnameHasError(true);
            setSurnameErrorMessage(t("RegistrationDialog.label15"));
            setSurnameCorrect(false);
        } else {
            setSurnameHasError(false);
            setSurnameErrorMessage("");
            setSurnameCorrect(true);
        }
        setSurnameInput(event.target.value);
    }

    let [institutionHasError, setInstitutionHasError] = useState(false);
    const [institutionErrorMessage, setInstitutionErrorMessage] = React.useState("");
    let [institutionInput, setInstitutionInput] = useState('');
    let [institutionCorrect, setInstitutionCorrect] = useState(false);

    function institutionHandleChange(event) {
        if (event.target.value.length === 0) {
            setInstitutionHasError(true);
            setInstitutionErrorMessage(t("RegistrationDialog.label16"));
            setInstitutionCorrect(false);
        } else {
            setInstitutionHasError(false);
            setInstitutionErrorMessage("");
            setInstitutionCorrect(true);
        }
        setInstitutionInput(event.target.value);
    }

    let [emailHasError, setEmailHasError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    let [emailInput, setEmailInput] = useState('');
    let [emailCorrect, setEmailCorrect] = useState(false);

    function emailHandleChange(event) {
        if (validateEmail(event.target.value)) {
            setEmailHasError(false);
            setEmailErrorMessage("");
            setEmailCorrect(true);
        } else {
            setEmailHasError(true);
            setEmailErrorMessage(t("RegistrationDialog.label17"));
            setEmailCorrect(false);
        }
        setEmailInput(event.target.value);
    }

    let [countryHasError, setCountryHasError] = useState(false);
    const [countryErrorMessage, setCountryErrorMessage] = React.useState("");
    let [countryInput, setCountryInput] = useState(null);
    let [countryCorrect, setCountryCorrect] = useState(false);

    function countryHandleChange(event, value, reason, details) {
        if (value) {
            setCountryErrorMessage("");
            setCountryHasError(false);
            setCountryCorrect(true);
        } else {
            setCountryErrorMessage(t("RegistrationDialog.label18"));
            setCountryHasError(true);
            setCountryCorrect(false);
        }
        setCountryInput(value);
    }

    const presentationTypeHandleChange = (event, value) => {
        // setPresentationType(value);
    };

    function checkInfoCorrect() {
        if (nameCorrect && surnameCorrect && emailCorrect && countryCorrect && institutionCorrect) {
            return true
        }
        if (!nameCorrect) {
            setNameHasError(true);
            setNameErrorMessage(t("RegistrationDialog.label14"))
        }
        if (!surnameCorrect) {
            setSurnameHasError(true);
            setSurnameErrorMessage(t("RegistrationDialog.label15"))
        }
        if (!emailCorrect) {
            setEmailHasError(true);
            setEmailErrorMessage(t("RegistrationDialog.label17"));
        }
        if (!institutionCorrect) {
            setInstitutionHasError(true);
            setInstitutionErrorMessage(t("RegistrationDialog.label16"));
        }
        if (!countryCorrect) {
            setCountryHasError(true);
            setCountryErrorMessage(t("RegistrationDialog.label18"));
        }
        return false
    }

    // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    //     nameInput = 'Óscar';
    //     surnameInput = 'Gómez Cárdenes';
    //     countryInput = "ES";
    //     emailInput = "ogomezca@ull.edu.es";
    //     institutionInput = "Unviersidad de La Laguna";
    //     nameHasError = false;
    //     nameCorrect = true;
    //     surnameHasError = false;
    //     surnameCorrect = true;
    //     countryHasError = false;
    //     countryCorrect = true;
    //     emailHasError = false;
    //     emailCorrect = true;
    //     institutionHasError = false;
    //     institutionCorrect = true;
    // }

    const form = useRef();

    function handleSend() {
        if (checkInfoCorrect()) {
            setActiveState(3);
            let template_id = 'template_gcxhaid'
            if (language === 'es') {
                template_id = 'template_z0w1wei'
            }
            emailjs.sendForm('service_o0y06tf', template_id, form.current, {
                publicKey: 'zyEGlT2QF0WdnEjVI',
            })
                .then(() => {
                    setActiveState(1);
                }, (error) => {
                    setActiveState(2);
                },);
        }
    }

    function closeDialog() {
        setOpen(false);
    }

    const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)

    function onChange(value) {
        setIsCaptchaSuccess(true)
    }

    return (<Box sx={{display: 'flex', justifyContent: 'center'}}>
        <Button variant='contained' sx={{width: width}} disabled='true'>
            {t("RegistrationDialog.button")}
        </Button>
        <Dialog onClose={closeDialog}
                open={open}
                fullWidth
                maxWidth="1000"
                sx={{backdropFilter: 'blur(5px)'}}>
            <DialogContent sx={{
                bgcolor: 'background.default'
            }}>
                {activeState === 0 ? (<Box>
                    <Typography align="center" variant="h4" sx={{m: 2}}>{t("RegistrationDialog.title")}</Typography>
                    <form id="email_form" ref={form}>
                        <Box>
                            <Stack>
                                <Divider/>
                                <TextField required
                                           label={t("RegistrationDialog.label1")}
                                           type='Text'
                                           onChange={nameHandleChange}
                                           helperText={nameErrorMessage}
                                           error={nameHasError}
                                           value={nameInput}
                                           name="user_name"
                                           sx={{m: 2, marginTop: 4}}/>
                                <TextField required
                                           label={t("RegistrationDialog.label2")}
                                           type='Text'
                                           onChange={surnameHandleChange}
                                           helperText={surnameErrorMessage}
                                           error={surnameHasError}
                                           value={surnameInput}
                                           name="user_surname"
                                           sx={{m: 2}}/>
                                <TextField required
                                           label={t("RegistrationDialog.label3")}
                                           type='email'
                                           onChange={emailHandleChange}
                                           helperText={emailErrorMessage}
                                           error={emailHasError}
                                           value={emailInput}
                                           name="user_email"
                                           sx={{m: 2}}/>
                                <TextField required
                                           label={t("RegistrationDialog.label4")}
                                           placeholder={t("RegistrationDialog.label5")}
                                           onChange={institutionHandleChange}
                                           helperText={institutionErrorMessage}
                                           error={institutionHasError}
                                           type='Text'
                                           name="user_institution"
                                           value={institutionInput}
                                           sx={{m: 2}}/>
                                <CountrySelector handleChange={countryHandleChange}
                                                 errorMessage={countryErrorMessage}
                                                 hasError={countryHasError}
                                                 input={countryInput}/>
                            </Stack>
                            <Divider/>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: "100%",
                                marginTop: 2,
                                marginX: 2

                            }}>
                                <Box sx={{
                                    display: "flex", flexDirection: "column", justifyContent: "center"
                                }}>
                                    <Typography align="center" variant="h5">
                                        {t("RegistrationDialog.label6")}
                                    </Typography>
                                </Box>
                                <ToggleButtonGroup sx={{marginX: 4}}
                                                   color="primary"
                                                   value={presentationType}
                                                   exclusive
                                                   onChange={presentationTypeHandleChange}
                                                   aria-label="Platform">
                                    <ToggleButton
                                        value={t("RegistrationDialog.label7")}>{t("RegistrationDialog.label7")}</ToggleButton>
                                    <ToggleButton
                                        value={t("RegistrationDialog.label8")}>{t("RegistrationDialog.label8")}</ToggleButton>
                                    <input name="with_abstract" hidden={true} readOnly value={presentationType}/>
                                </ToggleButtonGroup>
                            </Box>

                            <Box alignContent='center' alignSelf='center' alignItems='center' display='flex'>
                                <Typography sx={{marginX: 3, m: 2}} variant="body2" align="justify">
                                    {t("RegistrationDialog.label9")}
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex', justifyContent: 'center'
                            }}>
                                <ReCAPTCHA
                                    sitekey="6LcVpIQpAAAAALqFOAt2wm9_ZTWhyDvEl7YUYG2_"
                                    onChange={onChange}
                                />
                            </Box>

                            <Box sx={{
                                display: 'flex', justifyContent: 'center'
                            }}>
                                <Button variant="contained" endIcon={<SendIcon/>} sx={{m: 2}} onClick={handleSend}
                                        disabled={!isCaptchaSuccessful} >
                                    Enviar
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>) : (<div></div>)}

                {activeState === 1 ? (<Box sx={{
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box alignItems='center' sx={{display: 'flex', justifyContent: 'center'}}>
                        <CheckCircleIcon sx={{width: 100, height: 100}} color='success'/>
                    </Box>
                    <Divider sx={{m: 5}}/>
                    <Typography variant='h5' align='center'>
                        {t('RegistrationDialog.success')}
                    </Typography>
                    <Divider sx={{m: 5}}/>

                    <Button variant="contained" sx={{m: 2}} onClick={closeDialog}>
                        {t('RegistrationDialog.close')}
                    </Button>
                </Box>) : (<div></div>)}

                {activeState === 2 ? (<Box sx={{
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box alignItems='center' sx={{display: 'flex', justifyContent: 'center'}}>
                        <ErrorIcon sx={{width: 100, height: 100}} color='error'/>
                    </Box>
                    <Divider sx={{m: 5}}/>
                    <Typography variant='h5' align='center'>
                        {t('RegistrationDialog.fail')}
                    </Typography>
                    <Divider sx={{m: 5}}/>

                    <Button variant="contained" sx={{m: 2}} onClick={closeDialog}>
                        {t('RegistrationDialog.close')}
                    </Button>
                </Box>) : (<div></div>)}

                {activeState === 3 ? (<Box sx={{
                    display: 'flex', flexDirection: 'column'
                }}>
                    <Typography variant='h5' align='center'>
                        {t('RegistrationDialog.on_progress')}
                    </Typography>
                    <Box alignItems='center' sx={{display: 'flex', justifyContent: 'center', m: 5}}>
                        <CircularProgress size="5rem"/>
                    </Box>
                </Box>) : (<div></div>)}
            </DialogContent>
        </Dialog>
    </Box>);
}


const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null;
};